import React from 'react'

import SearchIcon from '@material-ui/icons/Search'

const Search = ({ handleOnSearch }) => {
  return (
    <div className="search">
      <form className="search__form">
        <div className="search__input-wrapper">
          <SearchIcon className="search__icon" />
          <input
            placeholder="Search"
            className="search__input"
            onChange={e => handleOnSearch(e.target.value)}
          />
        </div>
      </form>
    </div>
  )
}

export default Search
