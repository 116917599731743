import { graphql } from 'gatsby'
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Hero from '../components/EquipmentTabs/Hero'
import Footer from '../components/Footer'
import Layout from '../components/Layout'
import Paginator from '../components/Paginator'
import Post from '../components/Post'
import Search from '../components/Search'
import SearchPostCard from '../components/SearchPostCard'
import SEO from '../components/seo'


const BlogTemplate = ({ data, pageContext }) => {
  const [allWpPosts, setAllWpPosts] = useState([])
  const [filteredWpPosts, setFilteredWpPosts] = useState([])
  const [searchQuery, setSearchQuery] = useState('')

  const { wpPage, headerImage, blogPosts } = data
  const { seo } = wpPage
  const { currentPage, totalPages } = pageContext
  return (
    <Layout>
      <SEO title={seo.title} description={seo.metaDesc} />
      <section className="free-resources">
        <Hero
          type="primary"
          image={headerImage.childImageSharp.fluid}
          title="Our Blog"
        />
        <Container className="free-resources__container" fluid={true}>
          <Row>
            <Col className="free-resources__col-left" xl={7}>
              {blogPosts.nodes.map(post => (
                <Post
                  title={post.title}
                  featuredImage={
                    post.featuredImage?.node.localFile.childImageSharp.fluid
                  }
                  link={post.uri}
                  excerpt={post.excerpt}
                  date={post.date}
                  author={post.author.node.name}
                />
              ))}
              <Row>
                <Col>
                  <Paginator
                    slug="blog"
                    currentPage={currentPage}
                    totalPages={totalPages}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="free-resources__col-right" xl={4}>
              <Search
                handleOnSearch={searchText => {
                  setSearchQuery(searchText)
                  const filteredPosts = blogPosts.nodes.filter(({ title }) =>
                    title.toLowerCase().includes(searchText.toLowerCase())
                  )
                  setFilteredWpPosts(filteredPosts)
                  console.table({ filteredPosts, searchText, filteredWpPosts })
                }}
              />
              {searchQuery.length > 0 && filteredWpPosts.length === 0 && (
                <p>No Blog Post Found</p>
              )}
              {searchQuery.length > 0 && filteredWpPosts.length > 0 && (
                <>
                  <p>
                    {filteredWpPosts.length} results for {searchQuery}
                  </p>
                  {filteredWpPosts.map(post => (
                    <SearchPostCard
                      title={post.title}
                      featuredImage={
                        post.featuredImage.node.localFile.childImageSharp.fluid
                      }
                      link={post.uri}
                    />
                  ))}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query getBlogPostsAndPage($skip: Int!, $limit: Int!) {
    wpPage(uri: { eq: "/blog/" }) {
      seo {
        title
        metaDesc
      }
    }
    headerImage: file(relativePath: { eq: "blogheader.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPosts: allWpPost(
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        id
        title
        excerpt
        authorId
        date(formatString: "D MMM YYYY")
        slug
        uri
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        categories {
          nodes {
            name
          }
        }
        author {
          node {
            name
          }
        }
      }
    }
  }
`

export default BlogTemplate
